import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import api from 'services/api.js';
import { serverApi } from 'services/constante.js';

const styles = {
    boxAlertPlano: {
        marginTop: 15
    }
};


const useStyles = makeStyles(styles);

export default function MeuPlanoAtual(props) {

    const [nomePlano, setNomePlano] = useState("");
    const [subFrase, setSubFrase] = useState("");

    useEffect(() => {
        carregaDados();
    }, []);

    async function carregaDados() {
        let email_user = localStorage.getItem("CLLogin");

        var formdata = new FormData();
        formdata.append('function', 'verifica_plano_e_dias_faltantes_new');
        formdata.append('email', email_user);

        await api.post(serverApi.urlPHP + '/usuario.php', formdata).then(responseS => {
            console.log("Plano", responseS);
            let objData = responseS.data;
            if (objData.response == 'success') {
                if(objData.dados.Id == 1) {
                    //setPlanoGratuito(true);
                    setSubFrase("");
                } else {
                    if(objData.dados.DiasFaltantes < 0) {
                        setSubFrase("Plano já expirado, contrate um novo!");
                    } else {
                        setSubFrase(`Restam ${objData.dados.DiasFaltantes} ${objData.dados.DiasFaltantes > 1 ? 'dias' : 'dia'}`);
                    }
                }
                setNomePlano(objData.dados.NomePlano);
            }
        })
            .catch(error => {
                console.error(error);
            })
            .finally(() => { });
    }

    const classes = useStyles();

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <div className={classes.boxAlertPlano}>
                    <Alert severity="info">
                        <AlertTitle>Plano {nomePlano}</AlertTitle>
                        {subFrase}
                    </Alert>
                </div>
            </GridItem>
        </GridContainer>
    );
}