import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import api from 'services/api.js';
import { serverApi } from 'services/constante.js';
import Modal from '@material-ui/core/Modal';


function getModalStyle() {

    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 380,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  export default function SimpleModal() {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);
    
    useEffect(() => {
        carregaDados();
    }, []);

    async function carregaDados() {
        let email_user = localStorage.getItem("CLLogin");

        var formdata = new FormData();
        formdata.append('function', 'verifica_recurso_gratuito');
        formdata.append('email', email_user);

        await api.post(serverApi.urlPHP + '/usuario.php', formdata).then(responseS => {
            console.log("Reurso", responseS);
            let objData = responseS.data;
            if (objData.response == 'success') {
                if(objData.dados.Total <= 0) {
                    setOpen(true);
                } else {
                    setOpen(false);
                }
            }
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => { });
    }

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const body = (
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Parabéns 👏!</h2>
        <p id="simple-modal-description">Você ganhou um recurso de multa grátis🎉.</p>
        <p>⚠️Somente para multas de até R$ 293,47, e com prazo para defesa ou recurso com mais de 30 dias para o vencimento do prazo.</p>
        <p>Aproveite e não deixe de solicitar seu recurso gratuitamente.</p>
      </div>
    );
  
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>
    );
  }