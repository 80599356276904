import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import LoadingGrid from "components/Loading/LoadingGrid.js";

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerTitle: {
        textAlign: 'center',
        marginTop: 10
    },
    errorMessage: {
        color: '#f44336',
    },
    styleAlert: {
        marginTop: 15,
    },
});

const useStyles = makeStyles(styles);

export default function FormaPagamento(props) {
    const [paymentMethod, setPaymentMethod] = useState("");
    const [helperText, setHelperText] = useState("");
    const [disableItems, setDisableItems] = useState(false);
    const [loadingGrid, setLoadingGrid] = useState(true);

    const classes = useStyles();

    useEffect(() => {          
        carregarDados();
    },[props] );

    async function carregarDados() {
        setLoadingGrid(true);
        
        let email_user = localStorage.getItem("CLLogin");
        
        var formdata = new FormData();
        formdata.append('function', 'get_user_by_email');
        formdata.append('email', email_user);
        formdata.append('verifica_campos_pagamento', "sim");
        //formdata.append('email', "igordskadnas");
  
        await api.post(serverApi.urlPHP + '/usuario.php', formdata)
        .then(function (response) {
            //console.log(response);
            if (response.data.response == "error") {
                setDisableItems(true);
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(() => {   
            setLoadingGrid(false);
        });
    }

    const handleRadioChange = (event) => {
        setPaymentMethod(event.target.value);
        setHelperText("");
        //console.log(event.target.value);
    };

    const handleClickNext = () => {
        if(paymentMethod == "") {
            setHelperText("Escolha uma opção");
            return false;
        }

        let id_plano = props.match.params.idplano;
        let id_plano_detalhe = props.match.params.idplanodetalhe;

        if (paymentMethod == "CARTAO") {
            if (id_plano != undefined && id_plano_detalhe != undefined) {
                props.history.push({
                    pathname: `/app/planos/contratar/${id_plano}/${id_plano_detalhe}`
                });
            } else {
                props.history.push({
                    pathname: `/app/pe/contratar`,
                    state: { guidspesolo: props.location.state.guidspesolo, guidsquantity: props.location.state.guidsquantity }
                });
            }
        } else if (paymentMethod == "PICPAY") {
            //props.history.push(`/app/planos/contratar/picpay/${id_plano}/${id_plano_detalhe}`);
            if (id_plano != undefined && id_plano_detalhe != undefined) {
                props.history.push({
                    pathname: `/app/planos/contratar/picpay/${id_plano}/${id_plano_detalhe}`
                });
            } else {
                props.history.push({
                    pathname: `/app/pe/contratar/picpay`,
                    state: { guidspesolo: props.location.state.guidspesolo, guidsquantity: props.location.state.guidsquantity }
                });
            }
        } else if (paymentMethod == "BOLETO") {
            //props.history.push(`/app/planos/contratar/boleto/${id_plano}/${id_plano_detalhe}`);
            if (id_plano != undefined && id_plano_detalhe != undefined) {
                props.history.push({
                    pathname: `/app/planos/contratar/boleto/${id_plano}/${id_plano_detalhe}`
                });
            } else {
                props.history.push({
                    pathname: `/app/pe/contratar/boleto`,
                    state: { guidspesolo: props.location.state.guidspesolo, guidsquantity: props.location.state.guidsquantity }
                });
            }
        } else if (paymentMethod == "PIX") {
            //props.history.push(`/app/planos/contratar/picpay/${id_plano}/${id_plano_detalhe}`);
            if (id_plano != undefined && id_plano_detalhe != undefined) {
                /* props.history.push({
                    pathname: `/app/planos/contratar/picpay/${id_plano}/${id_plano_detalhe}`
                }); */
            } else {
                props.history.push({
                    pathname: `/app/pe/contratar/pix`,
                    state: { guidspesolo: props.location.state.guidspesolo, guidsquantity: props.location.state.guidsquantity }
                });
            }
        } else {
            setHelperText("Forma de pagamento inválida!");
            return false;
        }
        
    };

    return (
        <>
            <LoadingGrid display={loadingGrid} />
            <div style={{display: (!loadingGrid ? '' : 'none')}}>
                <div style={{display: (disableItems ? '' : 'none')}}>
                    <Alert severity="warning" classes={{root: classes.styleAlert}}>
                        <AlertTitle>Aviso</AlertTitle>
                        Para prosseguir o pagamento, é necessário que preencha todos os dados no perfil do usuário.
                    </Alert>
                </div>
                <h2 className={classes.headerTitle}>Forma de pagamento</h2>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <p>Escolha forma de pagamento:</p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <RadioGroup row aria-label="position" name="position" value={paymentMethod} onChange={handleRadioChange}>
                                                    <FormControlLabel
                                                        value="CARTAO"
                                                        control={<Radio color="primary" disabled={disableItems} />}
                                                        label="Cartão de crédito"
                                                    />
                                                    {props.match.params.idplano == undefined && props.match.params.idplanodetalhe == undefined ? (
                                                        <FormControlLabel
                                                            value="PIX"
                                                            control={<Radio color="primary" disabled={disableItems} />}
                                                            label="PIX"
                                                        />
                                                    ) : null}
                                                    {/* <FormControlLabel
                                                        value="PICPAY"
                                                        control={<Radio color="primary" disabled={disableItems} />}
                                                        label="PicPay"
                                                    />
                                                    <FormControlLabel
                                                        value="BOLETO"
                                                        control={<Radio color="primary" disabled={disableItems} />}
                                                        label="Boleto"
                                                    /> */}
                                                </RadioGroup>
                                                <FormHelperText className={classes.errorMessage}>{helperText}</FormHelperText>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <Button type="button" color="info" disabled={disableItems} onClick={handleClickNext}>Continuar</Button>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </>
    );
}